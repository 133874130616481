import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black small text-center text-white-50">
      <div className="container">
        ลิขสิทธิ์ถูกต้อง &copy; Look 'n Say, 2562
      </div>
    </footer>
  );
}
