import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class Subscribe extends React.Component {
  
  state = {
    email: null,
    phone: null,
    fname: null,
    msg: null,

    addToMailchimpResult: null,
  }

  _handleChange = (e) => {
    console.log({
        [`${e.target.name}`]: e.target.value,
    });
    this.setState({
        [`${e.target.name}`]: e.target.value,
    });
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    console.log('submit', this.state);
    addToMailchimp(this.state.email, this.state)
        .then(({ msg, result }) => {
            console.log('msg', `${result}: ${msg}`);
            if (result !== 'success') {
                throw msg;
            }
            this.setState({addToMailchimpResult: msg});
        })
        .catch((err) => {
            console.log('err', err);
            this.setState({addToMailchimpResult: err});          
        });
  }

  render () {
    return (
      <section id="signup" className="signup-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-8 mx-auto text-center">
              <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
              <h2 className="text-white mb-5">ลงทะเบียนอาสาสมัครกับ Look 'n Say</h2>
              <p className="text-white">(•ө•) รบกวนอาสาสมัครใจดี ช่วยฝากข้อมูลติดต่อไว้ให้ทางเรา เพื่อส่งข่าวสาร/กิจกรรมที่ต้องการให้คุณช่วยเหลือในอนาคตค่ะ</p>
  
              <form onSubmit={this._handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    placeholder="อีเมล"
                    name="email"
                    onChange={this._handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    placeholder="หมายเลขโทรศัพท์"
                    name="phone"
                    onChange={this._handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    placeholder="ชื่อ"
                    name="fname"
                    onChange={this._handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    placeholder="ฝากข้อความถึงเรา..."
                    name="msg"
                    onChange={this._handleChange}
                  />
                </div>
                { this.state.addToMailchimpResult ? 
                <div dangerouslySetInnerHTML={{__html: '<div class="alert alert-warning" role="alert">'+this.state.addToMailchimpResult+'</div>'}} />
                : null }
                <button type="submit" className="btn btn-primary mx-auto">
                  ลงทะเบียนอาสาสมัคร
                </button>
              </form>
              
            </div>
          </div>
        </div>
      </section>
    )
  }
}
