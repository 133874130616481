import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import demoImage1 from '../assets/images/learn-to-speak.jpg';
import demoImage2 from '../assets/images/play-themselves.jpeg';
import bgMaster from '../assets/images/work-with-teachers.jpg';
import ss from '../assets/images/ss.png';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              แอป Look 'n Say
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">แอปพลิเคชัน Look 'n Say</h2>
            <p className="text-white-50">
              แอปเรียนรู้การพูดสำหรับคนหูหนวก เล่นได้ด้วยตัวเอง ทุกที่ทุกเวลา เพื่อการฝึกฝนอย่างต่อเนื่อง
              <div>
                <a href='https://play.google.com/store/apps/details?id=com.onemoby.lookandsay&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className='img-fluid w-60pc' alt='ดาวน์โหลดได้จาก Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/th_badge_web_generic.png'/></a>
              </div>
            </p>
          </div>
        </div>
        <img src={ss} className="img-fluid" alt="" />
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={bgMaster} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>จุดเริ่มต้นและแรงบันดาลใจ</h4>
              <p className="text-black-50 mb-0">
                เรื่องราวของ Look 'n Say เริ่มขึ้นเมื่อได้มีโอกาสพบกับคุณครูนวล
                ครูผู้เชี่ยวชาญการสอนพูดเพียงท่านเดียวที่โรงเรียนโสตศึกษาทุ่งมหาเมฆ และแรงบันดาลใจจากคำพูดของท่าน
                 "<b>ถ้าพวกเราทำแอปนี้ออกมาได้จริง ๆ ครูเชื่อว่ามันช่วยเด็ก ๆ ได้ รีบทำให้สำเร็จนะ อีกไม่นานครูกำลังจะเกษียณแล้ว ครูรอใช้อยู่</b>"
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">ความท้าทายในการเรียนรู้</h4>
                  <p className="mb-0 text-white-50">
                    วิธีการสอนคือการออกเสียงให้ดูแบบคำต่อคำ ตัวต่อตัว ซึ่งใช้แรงกายแรงใจเยอะมาก
                    สิ่งสำคัญที่สุดคือ เด็ก ๆ ต้องทำการฝึกฝนอย่างเป็นประจำ ไม่อย่างนั้นก็จะลืม
                    แต่ปัญหาของคุณครูคือ พอปิดเทอมเด็กก็ลืมหมด ต้องมาทบทวนกันใหม่
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage2} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">น้อง ๆ คือ ความหวัง</h4>
                  <p className="mb-0 text-white-50">
                    ในการทำ Look 'n Say เราเห็นความหวัง ในแววตาของคุณครูที่โรงเรียน
                    เราเห็นความสนุก และความท้าทายที่เด็ก ๆ พยายามแข่งกันออกเสียง เพื่อให้ได้คะแนนขึ้นในแอป
                    นั่นยิ่งเป็นสิ่งที่ตอกย้ำพวกเราว่า เราต้องพัฒนา Look ’n Say ออกมาให้ตอบโจทย์ที่สุด สำหรับน้อง ๆ และช่วยแบ่งเบาภาระของท่านอาจารย์
                    และความตั้งใจสูงสุดของพวกเราคือ พัฒนาแพลตฟอร์มที่ สร้างแรงบันดาลใจให้เด็ก ๆ ผู้บกพร่องทางการได้ยิน ฝึกพูด ฝึกออกเสียง ได้อย่างถูกต้อง
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
